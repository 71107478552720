import { gtmPushEventsForNextPageLoad } from 'int_gtm_sfra/client/default/js/helpers/gtmHelpers.js';
import { loadAfterDOMContentLoaded } from '../util/domHelpers';
import { setJSON } from '../util/storage';
import formValidation from '../components/formValidation';
import * as recaptcha from '../thirdParty/recaptcha';

let tealium = null;
import('../app/tealium/tealium.js').then(({ default: tealiumLib }) => {
    tealium = tealiumLib;
});

export default {
    login() {
        // Listen on the document rather than the form, since the login modal can load after this loads
        document.addEventListener('submit', (e) => {
            const loginForm = e.target.closest('form.login');
            if (!loginForm) return;

            e.preventDefault();

            // Remove any existing alert errors
            recaptcha.clearErrors(loginForm);

            // create the pristine instance
            const pristine = new Pristine(loginForm);
            const valid = pristine.validate();

            if (!valid) {
                pristine.showFormErrors();
                return;
            }

            $(loginForm).spinner().start();

            fetch(loginForm.action, {
                method: 'POST',
                body: new FormData(loginForm),
            })
                .then((response) => response.json())
                .then((data) => {
                    $(loginForm).spinner().stop();

                    if (data.success) {
                        document.dispatchEvent(new Event('customer-login'));

                        if (data.modalUrl) {
                            const modalsLocalStorage = [
                                {
                                    url: data.modalUrl,
                                    showOnLoad: true,
                                    showOnCheckout: data.modalShowOnCheckout,
                                },
                            ];

                            setJSON('modals', modalsLocalStorage);
                        }

                        if (data.gtm) {
                            gtmPushEventsForNextPageLoad(data.gtm);
                        }

                        window.location.href =
                            data.redirectUrl !== '' ? data.redirectUrl : window.location;
                    } else if (data.recaptchaV3Error) {
                        if (data.errorMessage) {
                            recaptcha.showRecaptchaError(loginForm, data.errorMessage);
                        }

                        recaptcha.v2Execute(loginForm);
                    } else if (data.recaptchaV2Error) {
                        if (data.errorMessage) {
                            recaptcha.showRecaptchaError(loginForm, data.errorMessage);
                        }
                    } else if (data.redirectUrl) {
                        if (data.gtm) {
                            gtmPushEventsForNextPageLoad(data.gtm);
                        }
                        window.location.href = data.redirectUrl;
                    } else {
                        recaptcha.removeV2Input(loginForm);
                        formValidation(loginForm, data);
                    }
                })
                .catch((error) => {
                    $(loginForm).spinner().stop();
                    console.error('Error logging in: ', error);
                });
        });

        // Resubmit the form on recaptcha v2 callback
        window.v2LoginSubmitCallback = function () {
            const loginForms = document.querySelectorAll('form.login');
            let loginForm = loginForms[0];

            // If multiple login forms on page at same time (i.e. Pro Landing page, determine which one is active)
            loginForms.forEach((form) => {
                if (
                    form.querySelector('#login-form-email')?.value &&
                    form.querySelector('#login-form-password')?.value
                ) {
                    loginForm = form;
                }
            });

            loginForm.requestSubmit();
        };
    },

    register() {
        const registerUserObj = {};

        document.addEventListener('submit', (e) => {
            const registrationForm = e.target.closest('form.registration');
            if (!registrationForm) return;

            e.preventDefault();

            // Remove any existing alert errors
            recaptcha.clearErrors(registrationForm);

            // create the pristine instance
            const pristine = new Pristine(registrationForm);
            const valid = pristine.validate();

            if (!valid) {
                pristine.showFormErrors();
                return;
            }

            let isModal = false;

            if (registrationForm.classList.contains('js-registration--in-modal')) {
                isModal = true;

                if (registrationForm.closest('.js-modal-email-capture-center')) {
                    registerUserObj.captureLocation = 'modal center';
                } else if (registrationForm.closest('.js-modal-email-capture-bottom-right')) {
                    registerUserObj.captureLocation = 'modal right';
                }
            }

            $(registrationForm).spinner().start();

            const formData = new FormData(registrationForm);

            const proMembershipType = document.querySelector('#registrationForm-proMembershiptype');
            if (proMembershipType) {
                formData.append(proMembershipType.name, proMembershipType.value);
            }

            // Include unchecked checkboxes in form data so email optin's set to false override default values in the SFCC form definition
            registrationForm
                .querySelectorAll('input[type=checkbox]:not(:checked)')
                .forEach((input) => {
                    if (input.name) {
                        formData.set(input.name, 'false');
                    }
                });

            fetch(registrationForm.action, {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    $(registrationForm).spinner().stop();

                    if (data.success) {
                        if (isModal) {
                            registerUserObj.nameFirst = data.firstName;
                            registerUserObj.nameLast = data.lastName;
                            registerUserObj.email = data.email;
                            registerUserObj.acctNumber = data.customerID;

                            const modalRegister = document.querySelector(
                                '.modal-email-capture--register'
                            );
                            const modalRegisterThanks = document.querySelector(
                                '.modal-email-capture--register-thanks'
                            );
                            if (modalRegister) {
                                modalRegister.classList.add('hide');
                            }
                            if (modalRegisterThanks) {
                                modalRegisterThanks.classList.remove('hide');

                                const modalName = modalRegisterThanks.querySelector(
                                    '#modalEmailCaptureRegisterName'
                                );
                                if (modalName) {
                                    modalName.innerHTML = data.firstName;
                                }

                                const fade = modalRegisterThanks.querySelector('.fade');
                                if (fade) {
                                    fade.classList.add('show');
                                }
                            }

                            if (tealium) {
                                tealium.collect(
                                    'user_register',
                                    tealium.buildModalRegisterObject(registerUserObj)
                                );
                            }
                        } else {
                            if (data.analytics && data.analytics.userData) {
                                const userData = JSON.parse(data.analytics.userData);
                                if (userData.user_email_pref[0]) {
                                    const customerObj = {
                                        email_capture_loc: 'create account page',
                                        email: userData.user_email[0],
                                    };
                                    if (tealium) {
                                        tealium.collect(
                                            'email_signup',
                                            tealium.buildModalEmailSignupObject(customerObj)
                                        );
                                    }
                                }
                            }

                            if (data.gtm) {
                                gtmPushEventsForNextPageLoad(data.gtm);
                            }

                            window.location.href = data.redirectUrl;
                        }
                    } else if (data.recaptchaV3Error) {
                        if (data.errorMessage) {
                            const recaptchaErrorContainer = registrationForm.querySelector(
                                '.js-registration-recaptcha-container'
                            );
                            if (recaptchaErrorContainer) {
                                recaptcha.showRecaptchaError(
                                    registrationForm,
                                    data.errorMessage,
                                    recaptchaErrorContainer
                                );
                            } else {
                                recaptcha.showRecaptchaError(registrationForm, data.errorMessage);
                            }
                        }

                        recaptcha.v2Execute(registrationForm);
                    } else if (data.recaptchaV2Error) {
                        if (data.errorMessage) {
                            const recaptchaErrorContainer = registrationForm.querySelector(
                                '.js-registration-recaptcha-container'
                            );

                            if (recaptchaErrorContainer) {
                                recaptcha.showRecaptchaError(
                                    registrationForm,
                                    data.errorMessage,
                                    recaptchaErrorContainer
                                );
                            } else {
                                recaptcha.showRecaptchaError(registrationForm, data.errorMessage);
                            }
                        }
                    } else if (data.redirectUrl) {
                        if (data.gtm) {
                            gtmPushEventsForNextPageLoad(data.gtm);
                        }
                        window.location.href = data.redirectUrl;
                    } else {
                        recaptcha.removeV2Input(registrationForm);
                        formValidation(registrationForm, data);
                    }
                })
                .catch((error) => {
                    $(registrationForm).spinner().stop();
                    console.error('Error registering: ', error);
                });
        });

        // Resubmit the form on recaptcha v2 callback
        window.v2RegisterSubmitCallback = function () {
            const registrationForm = document.querySelector('form.registration');
            registrationForm.requestSubmit();
        };
    },
};

/**
 * Removes url parameters that serve a one-time notice to users on redirect, to make sure the messages don't improperly repeat
 */
function cleanURL() {
    window.setTimeout(() => {
        const passwordResetRemoved = window.location.href.replace('&passwordReset=true', '');
        window.history.replaceState('', '', passwordResetRemoved);
    }, 250);
}

loadAfterDOMContentLoaded(cleanURL);
