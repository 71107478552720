/**
 * @param {Object} event - single event object
 */
function assignPageVariables(event) {
    const pageVariablesToNotOverwriteIfExist = ['user_acct_authenticated', 'user_acct_number'];
    const stashedPageVariableValues = {};

    // Store properties we do not want to overwrite with page variables, if they exist
    pageVariablesToNotOverwriteIfExist.forEach((property) => {
        if (event[property]) {
            stashedPageVariableValues[property] = event[property];
        }
    });

    // Copy all page variables to event
    Object.assign(event, window.gtmPageVariables);

    // Restore any original existing page variables to event
    Object.keys(stashedPageVariableValues).forEach((property) => {
        event[property] = stashedPageVariableValues[property]; // eslint-disable-line no-param-reassign
    });

    delete event.pageVariables; // eslint-disable-line no-param-reassign
}

function getVideoMileStone(details, milestones) {
    const duration = Math.round(details.video_length);
    const currentTime = parseInt(details.video_current_time, 10);
    const percentComplete = (currentTime / duration) * 100;

    if (milestones[0] <= percentComplete) {
        return milestones.shift();
    }

    return null;
}

/**
 * @param {Object} eventArray - Array of GTM event objects
 */
export const gtmPushEvents = (eventArray) => {
    window.dataLayer = window.dataLayer || [];

    eventArray.forEach((event) => {
        if (event.ecommerce) {
            window.dataLayer.push({
                ecommerce: null,
            });
        }

        if (event.event === 'apply_filter') {
            const pastFilters = window.dataLayer.findLast(
                (element) => element.event === 'apply_filter'
            );

            if (pastFilters) {
                Object.keys(pastFilters).forEach((key) => {
                    if (key.startsWith('filter_') && !event[key]) {
                        event[key] = ''; // eslint-disable-line no-param-reassign
                    }
                });
            }
        }

        if (event.pageVariables === true) {
            if (window.gtmPageVariables) {
                assignPageVariables(event);
                window.dataLayer.push(event);
            } else {
                document.addEventListener('pageVariablesBuilt', () => {
                    assignPageVariables(event);
                    window.dataLayer.push(event);
                });
            }
        } else {
            window.dataLayer.push(event);
        }
    });
};

function youtubeEventListeners() {
    const milestones = [25, 50, 75, 90];

    document.addEventListener('loadeddata', (e) => {
        const eventArray = [
            {
                event: 'video_load',
                video_title: e.detail.title,
                video_id: e.detail.video_id,
                video_duration: e.detail.video_length,
            },
        ];

        gtmPushEvents(eventArray);
    });

    document.addEventListener('play', (e) => {
        const eventArray = [
            {
                event: 'video_play',
                video_title: e.detail.title,
                video_id: e.detail.video_id,
                video_duration: e.detail.video_length,
            },
        ];

        gtmPushEvents(eventArray);
    });

    document.addEventListener('pause', (e) => {
        const eventArray = [
            {
                event: 'video_pause',
                video_title: e.detail.title,
                video_id: e.detail.video_id,
                video_duration: e.detail.video_length,
            },
        ];

        gtmPushEvents(eventArray);
    });

    document.addEventListener('ended', (e) => {
        const eventArray = [
            {
                event: 'video_complete',
                video_title: e.detail.title,
                video_id: e.detail.video_id,
                video_duration: e.detail.video_length,
            },
        ];

        gtmPushEvents(eventArray);
    });

    document.addEventListener('milestone_check', (e) => {
        const milestone = getVideoMileStone(e.detail, milestones);
        if (milestone) {
            const eventArray = [
                {
                    event: 'video_progress',
                    video_milestone: milestone,
                    video_title: e.detail.title,
                    video_id: e.detail.video_id,
                    video_duration: e.detail.video_length,
                },
            ];

            gtmPushEvents(eventArray);
        }
    });
}

/**
 * @param {Object} eventArray - Array of GTM event objects
 */
export const gtmPushEventsForNextPageLoad = (eventArray) => {
    const key = 'gtmDelayedEvents';
    const gtmDelayedEvents = window.localStorage.getItem(key);
    let existingDelayedEvents = null;
    const newEvents = [];

    eventArray.forEach((event) => {
        if (event.pageVariables === true && window.gtmPageVariables) {
            assignPageVariables(event);
        }

        newEvents.push(event);
    });

    if (gtmDelayedEvents) {
        try {
            existingDelayedEvents = JSON.parse(gtmDelayedEvents);
        } catch (e) {
            console.warn('Error parsing GTM delayed events', e);
        }
    }

    window.localStorage.setItem(
        key,
        JSON.stringify(existingDelayedEvents ? existingDelayedEvents.concat(newEvents) : newEvents)
    );
};

/**
 * @param {String} email - Email to hash
 * @returns {Promise} - hashed email string
 */
export const getHashedEmail = async (email) => {
    const textAsBuffer = new TextEncoder().encode(email);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((item) => item.toString(16).padStart(2, '0')).join('');
};

/**
 * @description listens for clicks on elements with data-gtm attribute, and triggers associated event on next page load
 */
export const initListeners = () => {
    document.addEventListener('click', (event) => {
        const $gtmEventElement = event.target.closest('[data-gtm]');
        if (!$gtmEventElement) return;

        // For product tiles, ignore clicks on non-links
        if (event.target.closest('.product-tile') && !event.target.closest('a')) {
            return;
        }

        try {
            const gtmData = JSON.parse($gtmEventElement.dataset.gtm);
            gtmPushEventsForNextPageLoad(gtmData);
        } catch (e) {
            console.error(e);
        }
    });

    youtubeEventListeners();
};
